import styled from 'styled-components/macro'
import Paper from '@material-ui/core/Paper'

export const DatesFilterContainer = styled(Paper)`
  position:absolute;
  width: ${({ customcss }) => customcss.width};
  padding: 10px;
  z-index: ${({ isPickerOpen }) => isPickerOpen ? 1400 : 1500};
  right: ${({ customcss }) => customcss.right};
  left: ${({ customcss }) => customcss.left};
  top: ${({ customcss }) => customcss.top};
  display: flex;
  align-items: flex-end;
  transition: opacity 229ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 153ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12);
  .MuiButton-root{
    margin-left: 10px;
    margin-bottom: 8px;
  }
  `

  