import styled from 'styled-components/macro'


export const ChatHistoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
  width: 100%;
  padding: 10px 20px;
  background: #fff;
  height: 400px;
  max-height: 400px;
  min-height: 400px;
  overflow: auto;
  border: 1px solid #dcdcdc;
  .ChatHistory {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
`

export const MessageInfo = styled.div`
  display: flex;
  gap: 2px;
  align-self: flex-start;
  font-size: 11px;
`
export const SenderName = styled.div`
  font-weight: bold;
`
export const BotContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 13px;
`
export const MessageItem = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 4px;
  background: #eee;
  padding: 4px 10px;
  width: fit-content;
  border-bottom-left-radius: 0px;
  max-width: 70%;
  font-weight: 900;

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    border-top: 12px solid transparent;
    border-right: 12px solid #eee;
    right: 100%;
  }

  div {
    color: #555;    
  }

  &.myMessage {
    align-self: flex-end;
    background: #dcdcdc;
    border-bottom-right-radius: 0px;

    ${MessageInfo} {
      align-self: flex-end;
    }

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      border-top: 12px solid transparent;
      border-left: 12px solid #dcdcdc;
      border-right: none;
      left: 100%;
    }

    &:before {
      content: unset;
    }
  }
  &.file {
    max-width: 500px;
  }
  .message-content {
    word-break: break-all;
    white-space: pre-wrap; 
  }
  .file-content{
    & img, video {
      width: 250px;
      height: 250px;
      object-fit: contain
    }
  }
  &.botMessage {
    background: transparent;
    margin: 0 auto;
    font-style: italic;
    text-align: center;
    ${MessageInfo} {
      font-size: 13px;
      text-align: center;
      font-weight: normal;
      div:first-child {
        font-weight: normal;
      }
    }
    &:after, &:before {
      content: unset;
      display: none;
    }
  }
`
export const FileImage = styled.img`
  width: 100%;
  object-fit: contain
`
