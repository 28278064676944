import styled from 'styled-components/macro'
import MaterialTabs from '@material-ui/core/Tabs'
import MaterialTab from '@material-ui/core/Tab'


export const Tabs = styled(MaterialTabs)`
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)
`

export const Tab = styled(MaterialTab)`
  font-size: 16px;
  font-weight: 700;
  margin-right: 20px;
  color: rgba(0, 0, 0, 0.54);
  min-width: unset;

  &:last-child {
    margin: 0px;
  }

  &:hover {
    color: #0f3d56;
  }
  &:active {
    color: #0f3d56;
  }
  &:selected {
    color: #0f3d56;
  }
  @media (max-width:950px){
    font-size:12px;
  }
`

export const TabPanelContainer = styled.div`
  margin: 0px 10px;
  padding: 20px 10px;
`