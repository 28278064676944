import styled from 'styled-components/macro'


export const ShortcutsModalContainer = styled.div`
  padding: 16px 35px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
`

export const ModalTitle = styled.h2`
  font-size: 30px;
  margin: 0px;
  margin-left: auto;
`

export const ShortcutsSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  width: 100%;
`

export const ShortcutsSectionTitle = styled.h4`
  font-size: 20px;
  margin: 0px !important;
`

export const ShortcutsSectionList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-left: 30px;
  margin-top: 10px;
`

export const Shortcut = styled.div`
  display: flex;
  justify-content: space-between;

  div {
    font-size: 16px;
    flex: 2;
  }

  div:first-child {
    flex: 1;
  }
`

export const ModalHeaderContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;

  .MuiButtonBase-root {
    margin-left: auto;
    border-radius: 50%;
    padding: 8px;
    min-width: 0px;
  }
`