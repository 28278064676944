import styled from 'styled-components/macro'


export const VisitorsPageContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width:950px){
    button{
      padding: 5px 15px!important;
      font-size: 12px!important;
    }
  }
`

export const SearchInputContainer = styled.div`
  width: 250px;
  @media (max-width:950px){
    width: unset;
  }
`

export const SearchBar = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`

export const VisitorsListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 15px;

  .MuiTypography-root {
    color: #555;
    @media (max-width:950px){
      font-size:12px;
    }
  }
`