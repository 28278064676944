import styled from 'styled-components/macro'


export const NotificationsTabContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const TabTitle = styled.h1`
  color: #3c4858;
  margin: 0px 0px 10px;
`

export const TabInfo = styled.h4`
  font-size: 14px;
  text-align: start;
  margin: 8px;
  color: #555;
`

export const OptionsListContainer = styled.div`
  display: flex;
  margin-top: 15px;
  flex-direction: column;
`

export const OptionItem = styled.div`
  display: flex;
  gap: 15px;
`

export const OptionText = styled.div`
  margin: 8px;
  color: #555;
  font-size: 15px;
  &.Label {
    width: 120px;
    text-align: start;
  }
  font-weight: 500;
`