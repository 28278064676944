import styled from "styled-components/macro";
import MaterialListItem from "@material-ui/core/ListItem";
import MaterialListItemIcon from "@material-ui/core/ListItemIcon";
import MaterialListItemText from "@material-ui/core/ListItemText";
import MaterialList from "@material-ui/core/List";

export const ListItem = styled(MaterialListItem)`
  transition: background 300ms linear;
  border-radius: 3px;
  padding: 4px 16px;
  
  &.Mui-selected {
    background: #0f3d56;
    &:hover {
      background: #0f3d56;
    }
  }
  justify-content: center;
  @media (min-width:1400px){
    font-size:15px;
  }
`;

export const ListItemIcon = styled(MaterialListItemIcon)`
  min-width: 0px;
  color: rgba(255, 255, 255, 0.8);
  @media (min-width:1400px){
    svg {
      width: 24px !important;
      height: 24px !important;
    }
  }
  @media only screen and (max-width: 1399px) and (min-width: 951px){
    svg {
      width: 18px !important;
      height: 18px !important;
    }
  }
  @media (max-width:950px){
    svg {
      width: 16px !important;
      height: 16px !important;
    }
  }
`;

export const ListItemText = styled(MaterialListItemText)`
  color: #fff;
  margin-left: 15px;

  &.SubMenuItem {
    margin-left: 0;
    text-align: center;
  }

  .MuiTypography-root {
    font-weight: 300;
    font-size: 14px;
  }
  @media (min-width: 1920px){
  .MuiTypography-root {
    font-weight: 300;
    font-size: 14px;
  }
  }
  @media (max-width:950px){
    .MuiTypography-root {
      font-size: 12px;
    }
  }
`;

export const List = styled(MaterialList)`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 8px 10px 0px 10px;
  overflow-y: auto;
  margin: 0px 0px 105px;

  & span{
    color: white;
  }

  &.sidebarClosed {
    padding: 8px 15px 0px 15px;

  }
  &::-webkit-scrollbar {
    display: none;
  }


  @media (max-width:950px){
    &.sidebarClosed{
      padding:8px 0px 0px 0px;
    }
  }
`;